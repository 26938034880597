import React from 'react'

import './header.scss'

import { MdOutlineErrorOutline } from "react-icons/md";
import { AiOutlineLoading } from "react-icons/ai";

import logo from "../../assets/images/logo.png"

export default function Header({ status, connect, account }) {
  const connectWallet = () => {
    connect();
  };

  return (
    <div className="header-container">
        <img src={logo} alt="lgb logo" className="logo"/>

        {status === "connected"
          ? <div className="wallet-address">
              <p>
                {account
                  ? account.slice(0, 5) + ". . ." + account.slice(-4)
                  : "Not Connected"}
              </p>
            </div>

          : <button 
              className={
                status === "initializing" || status === "connecting" ? "connect-btn init"
                : status === "unavailable" ? "connect-btn unavailable"
                : "connect-btn"
              }
              onClick={
                status === "notConnected"
                ? connectWallet
                : null
              }
            >
              {status === "initializing" || status === "connecting" ?
                  <><AiOutlineLoading /> Initializing</>
              : null}

              {status === "unavailable" ? 
                <><MdOutlineErrorOutline /> Install MetaMask</>
              : null}
              
              {status === "notConnected" ? 
                <>Connect</>
              : null}
            </button>
        }
    </div>
  )
}
