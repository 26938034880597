import React, {useState, useEffect} from "react";

import "./mintPassScreen.scss";

import { IoMdLink } from "react-icons/io"

import Loader from "../Loader/Loader";
import Counter from "../Counter/Counter";
import DebugBox from "../DebugBox/DebugBox";

import config from "../../utils/config.json"

export default function MintPassScreen({
  passLoading,
  passEligibility,
  mintPaused,
  saleStatus,
  saleStartTime,
  saleSupply,
  mintPrice,
  mintLimit,
  mintedAmount,
  mintAmount,
  setMintAmount,
  mintFunction
}) {
  const [mintStatus, setMintStatus] = useState({status: false, error: "Unknown Error - Try Reloading Page"});
  const [saleType, setSaleType] = useState(null);
  const [saleName, setSaleName] = useState(null);

  // saleStatus = {...saleStatus, labGrownBeasts: "started"}
  // saleSupply = {...saleSupply, labGrownBeasts: 0}
  // mintedAmount = {...mintedAmount, labGrownBeasts: 10}

  // Clamp number between two values with the following line:
  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  const checkMintStatus = () => {
    if(saleStatus[saleType] === "notStarted")
      setMintStatus({status: false, error: `Sale will begin on ${new Date(saleStartTime[saleType] * 1000).toLocaleString('en-US', {
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        hour12: true,
        minute: 'numeric',
      })}`});

    else if(saleStatus[saleType] === "saleOver")
      setMintStatus({status: false, error: `Sale Over`, opensea: true});

    else if(saleStatus[saleType] === "started") {
      if(saleSupply[saleType]) {
          setMintStatus({status: true, error: `Unknown Error - Try Reloading Page`});
      }
      else {
        setMintStatus({status: false, error: `Sold Out!`, opensea: true});
      }
    }
  }

  const saleNameHandler = () => {
    if(saleType === "beasts")
      setSaleName("Beast Sale");
    else if(saleType === "labGrownBeasts")
      setSaleName("Lab Grown Beast Sale");
    else if(saleType === "postMint")
      setSaleName("Wild DNA Sale");
  }

  useEffect(() => {
    if(passEligibility) {
      const walletType = passEligibility[1] === 1 ? "beasts" 
                        : passEligibility[1] === 2 ? "labGrownBeasts"
                          : passEligibility[1] === 3 ? "postMint" : null;
      setSaleType(walletType);
      saleNameHandler();
      checkMintStatus();
    }
    setMintAmount(1);
  }, [passEligibility, passLoading]);

  return (
    <div className="mint-pass-screen">
      {passLoading ? 
        <Loader />
        :<div className="mint-pass-container">
          {passEligibility ?
            <>
              {mintPaused ? 
                <div className="mint-paused">
                  <h1>Mint Paused</h1>
                </div>
                :<>
                  {mintStatus.status 
                    ?<>
                      {mintedAmount[saleType] >= mintLimit[saleType]
                        ? <div className="minted">
                            <h1>Thanks for Minting!</h1>
                            <h2>View your NFT</h2>
                            <a href={config.openSea}><IoMdLink />OpenSea</a>
                          </div>
                        : <div className="mint-container">
                            {/* <h1 className="sale-type">{saleName}</h1> */}
                            <Counter maxCount={clamp(mintLimit[saleType] - mintedAmount[saleType], 1, saleSupply[saleType])} price={mintPrice[saleType]} count={mintAmount} setCount={setMintAmount}/>
                            <button
                              className="mint-button"
                              onClick={() => mintFunction(saleType)}
                            >
                              MINT
                            </button>
                          </div> 
                      }
                    </>
                    :<div className="error">
                      {saleStatus[saleType] === "notStarted" 
                        ? <h2>You are on the Whitelist!<br/>{mintStatus.error}</h2>
                        : <h1>{mintStatus.error}
                            {mintStatus.opensea ? <a href={config.openSea}><IoMdLink />OpenSea</a> : null}
                          </h1>
                      }
                    </div>
                  }
                </>
              }
            </>
            :<div className="error-not-list">
              <h1>Oops, you are NOT <br/>on the Whitelist.</h1>
            </div>
          }
        </div>
      }
    </div>
  );
}