import { useState, useEffect } from 'react';
import { useMetaMask } from "metamask-react";
import { ToastContainer, toast } from "react-toastify";

import './app.scss';
import "react-toastify/dist/ReactToastify.css";

import Header from './components/Header/Header';
import WalletLogin from './components/WalletLogin/WalletLogin';
import MintPassScreen from "./components/MintPassScreen/MintPassScreen";
import DebugBox from './components/DebugBox/DebugBox';

// Backend Imports
import { getWhitelist } from "./utils/backendApi";
import {
  providerHandler,
  beasts,
  readBeastsRegister,
  labGrownBeasts,
  readLabGrownBeastsRegister,
  postMint,
  readPostMintRegister,
  beastsMint,
  labGrownBeastsMint,
  postMintSale,
  ownerRemainingTokens,
  totalSupply,
  maxSupply,
  paused,
} from "./web3/contractInteraction";

function App() {
  const { status, connect, account } = useMetaMask();
  const { ethereum } = window;

  const [passEligibility, setPassEligibility] = useState(false); // whitelist check
  const [mintPaused, setMintPaused] = useState(false);

  const [passLoading, setPassLoading] = useState(true); // set loader state

  const [saleStatus, setSaleStatus] = useState({beasts: null, labGrownBeasts: null, postMint: null}); // check whitelist sale status

  const [saleSupply, setSaleSupply] = useState({beasts: false, labGrownBeasts: false, postMint: false});
  const [saleStartTime, setSaleStartTime] = useState({beasts: null, labGrownBeasts: null, postMint: null});
  const [mintPrice, setMintPrice] = useState({beasts: 0.00, labGrownBeasts: 0.00, postMint: 0.00});
  const [mintLimit, setMintLimit] = useState({beasts: 0, labGrownBeasts: 0, postMint: 0});
  const [mintedAmount, setMintedAmount] = useState({beasts: 0, labGrownBeasts: 0, postMint: 0}); // check if any pass already minted

  const [mintAmount, setMintAmount] = useState(1);

  const handleChainChange = async (reload) => {
    const currentChain = await window.ethereum.request({
      method: "eth_chainId",
    });

    const chainID = 1;

    if (currentChain !== `0x${chainID}`) {
      setPassLoading(true);

      toast("Switch to Ethereum Mainnet", {
        position: "top-center",
        type: "warning",
        autoClose: 5000,
        hideProgressBar: true,
        toastId: currentChain,
      });

      window.ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${chainID}` }],
        })
        .then(() => {
          if (reload) window.location.reload(false);
        });
    }
  };

  if (ethereum) 
    ethereum.on("chainChanged", handleChainChange);

  // mint function
  const mintHandler = async (mintType) => {
    handleChainChange(true);
    setPassLoading(true);

    if(mintType === "beasts") {
      const price = (mintAmount * mintPrice.beasts)
      try {
        await beastsMint(price.toFixed(4).toString(), passEligibility, mintAmount);
        toast.success("Transaction Successful!", {

          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
        });

      } catch (e) {
        console.log(e.message);
        toast.error(
          e.code === "INSUFFICIENT_FUNDS"
            ? "Insufficient balance."
            : e.code === "UNPREDICTABLE_GAS_LIMIT"
            ? "Something went wrong"
            : "Transaction Unsuccessful!",
          {
            position: "top-center",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: true,
          }
        );
      }
    }
    else if(mintType === "labGrownBeasts") {
      const price = (mintAmount * mintPrice.labGrownBeasts)
      try {
        await labGrownBeastsMint(price.toFixed(4).toString(), passEligibility, mintAmount);
        toast.success("Transaction Successful!", {
          position: "top-center",
          autoClose: 5000, 
          hideProgressBar: true,
        });
      } catch (e) {
        console.log(e.message);
        toast.error(
          e.code === "INSUFFICIENT_FUNDS"
            ? "Insufficient balance."
            : e.code === "UNPREDICTABLE_GAS_LIMIT"
            ? "Something went wrong"
            : "Transaction Unsuccessful!",
          {
            position: "top-center",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: true,
          }
        );
      }
    }
    else if(mintType === "postMint") {
      const price = (mintAmount * mintPrice.postMint)
      try {
        await postMintSale(price.toFixed(4).toString(), passEligibility, mintAmount);
        toast.success("Transaction Successful!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
        });
      } catch (e) {
        console.log(e.message);
        toast.error(
          e.code === "INSUFFICIENT_FUNDS"
            ? "Insufficient balance."
            : e.code === "UNPREDICTABLE_GAS_LIMIT"
            ? "Something went wrong"
            : "Transaction Unsuccessful!",
          {
            position: "top-center",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: true,
          }
        );
      }
    }
    
    // updated the minted amount
    await checkMinted();

    // re-check for available token supply
    const beastsData = await beasts();
    const labGrownBeastsData = await labGrownBeasts();
    const postMintData = await postMint();
    const totalMinted = await totalSupply();
    const max = await maxSupply();
    const owner = await ownerRemainingTokens();

    checkSupply(beastsData, labGrownBeastsData, postMintData, totalMinted, max, owner);

    setMintAmount(1);

    setPassLoading(false);
  };

  // function to debug toasts
  const makeToast = () => {
    toast.success("Transaction Successful!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
    });
    toast("Switch to Ethereum Mainnet", {
      position: "top-center",
      type: "warning",
      autoClose: 5000,
      hideProgressBar: true
    });
    toast.error(
      "Insufficient balance.",
      {
        position: "top-center",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: true,
      }
    );
  }

  // function to check if sale started
  const checkSaleStatus = (beastsData, labGrownBeastsData, postMintData) => {
    setSaleStartTime({beasts: beastsData.startTime, labGrownBeasts: labGrownBeastsData.startTime, postMint: postMintData.startTime});

    const currentTimeSeconds = parseInt(Date.now() / 1000);

    let beasts, labGrownBeasts, postMint;

    // check beasts status
    if (beastsData.startTime < currentTimeSeconds && beastsData.endTime > currentTimeSeconds) {
      beasts = "started";
    } 
    else if (beastsData.startTime > currentTimeSeconds) {
      beasts = "notStarted";
    } 
    else {
      beasts = "saleOver";
    }

    // check labGrownBeasts status
    if (labGrownBeastsData.startTime < currentTimeSeconds && labGrownBeastsData.endTime > currentTimeSeconds) {
      labGrownBeasts = "started";
    } 
    else if (labGrownBeastsData.startTime > currentTimeSeconds) {
      labGrownBeasts = "notStarted";
    } 
    else {
      labGrownBeasts = "saleOver";
    }

    // check postMint status
    if (postMintData.startTime < currentTimeSeconds && postMintData.endTime > currentTimeSeconds) {
      postMint = "started";
    } 
    else if (postMintData.startTime > currentTimeSeconds) {
      postMint = "notStarted";
    } 
    else {
      postMint = "saleOver";
    }

    setSaleStatus({beasts: beasts, labGrownBeasts: labGrownBeasts, postMint: postMint});
  };

  // function to check supply / per wallet mint limit / mint price
  const checkSupply = (beastsData, labGrownBeastsData, postMintData, totalMinted, max, owner) => {
    const supplyAfterReserved = max - totalMinted - owner - postMintData.maxAvailable;
    setSaleSupply({
      beasts: (supplyAfterReserved > beastsData.maxAvailable ? beastsData.maxAvailable : supplyAfterReserved),
      labGrownBeasts: (supplyAfterReserved > labGrownBeastsData.maxAvailable ? labGrownBeastsData.maxAvailable : supplyAfterReserved),
      postMint: (postMintData.maxAvailable)
    });

    setMintLimit({
      beasts: parseInt(beastsData.purchaseLimit),
      labGrownBeasts: parseInt(labGrownBeastsData.purchaseLimit),
      postMint: parseInt(postMintData.purchaseLimit),
    });

    setMintPrice({
      beasts: parseFloat(beastsData.price),
      labGrownBeasts: parseFloat(labGrownBeastsData.price),
      postMint: parseFloat(postMintData.price),
    });
  }

  // function to check if already minted
  const checkMinted = async () => {
    let beastsMintedPass, labGrownBeastsMintedPass, postMintMintedPass;

    beastsMintedPass = await readBeastsRegister(account);
    labGrownBeastsMintedPass = await readLabGrownBeastsRegister(account);
    postMintMintedPass = await readPostMintRegister(account);

    setMintedAmount({beasts: beastsMintedPass, labGrownBeasts: labGrownBeastsMintedPass, postMint: postMintMintedPass});
  };

  const accountSetup = async () => {
    setPassLoading(true);
    handleChainChange(true);

    const account = await providerHandler();

    // check whitelist
    const userPassEligibility = await getWhitelist(account);
    // const userPassEligibility = {signature: [true, 2]}
    setPassEligibility(userPassEligibility.signature);

    const isPaused = await paused();
    setMintPaused(isPaused);

    if(!isPaused && userPassEligibility) {
      const beastsData = await beasts();
      const labGrownBeastsData = await labGrownBeasts();
      const postMintData = await postMint();

      checkSaleStatus(beastsData, labGrownBeastsData, postMintData);
    
      const totalMinted = await totalSupply();
      const max = await maxSupply();
      const owner = await ownerRemainingTokens();
      checkSupply(beastsData, labGrownBeastsData, postMintData, totalMinted, max, owner);

      await checkMinted();
    }

    setMintAmount(1);

    setPassLoading(false);
  };

  useEffect(() => {
    if (account) {
      accountSetup();
    }
  }, [account]);

  return (
    <div className="app">
      <Header account={account} status={status} connect={connect} />
      {status === "connected" 
        ? <MintPassScreen
            passLoading={passLoading}
            passEligibility={passEligibility}
            mintPaused={mintPaused}
            saleStatus={saleStatus}
            saleStartTime={saleStartTime}
            saleSupply={saleSupply}
            mintPrice={mintPrice}
            mintLimit={mintLimit}
            mintedAmount={mintedAmount}
            mintAmount={mintAmount}
            setMintAmount={setMintAmount}
            mintFunction={mintHandler}
        /> 
        : <WalletLogin status={status} connect={connect} handleChainChange={handleChainChange} />
      }
      {/* <DebugBox makeToast={makeToast}/> */}
      <ToastContainer />
      <div className="bg-cover"></div>
    </div>
  );
}

export default App;
