import { ethers, utils } from "ethers";
import config from "./config.json"
import abi from "./abi.json";

let contract;
const contractAddress = config.contractAddress;

export const providerHandler = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const account = await provider.listAccounts();
    const address = account[0];
    const signer = provider.getSigner();
    contract = new ethers.Contract(contractAddress, abi, signer);
    return address;
};

export const providerHandlerReadOnly = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  contract = new ethers.Contract(contractAddress, abi, provider);
};

// read functions
export const beasts = async () => {
  const n = await contract.beasts();
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    purchaseLimit: n.purchaseLimit.toNumber(),
    maxAvailable: n.maxAvailable.toNumber(),
    price: utils.formatEther(n.price)
  };
}

export const readBeastsRegister = async(address)=>{
  const n = await contract.readBeastsRegister(address);
  return n.toNumber()
}

export const labGrownBeasts = async () => {
  const n = await contract.labGrownBeasts();
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    purchaseLimit: n.purchaseLimit.toNumber(),
    maxAvailable: n.maxAvailable.toNumber(),
    price: utils.formatEther(n.price)
  };
}

export const readLabGrownBeastsRegister = async(address)=>{
  const n = await contract.readLabGrownBeastsRegister(address);
  return n.toNumber()
}

export const postMint = async () => {
  const n = await contract.postMint();
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    purchaseLimit: n.purchaseLimit.toNumber(),
    maxAvailable: n.maxAvailable.toNumber(),
    price: utils.formatEther(n.price)
  };
}

export const readPostMintRegister = async(address)=>{
  const n = await contract.readPostMintRegister(address);
  return n.toNumber()
}

export const paused = async () => {
  const n = await contract.paused()
  return n
}

export const maxSupply = async () => {
  const n = await contract.maxSupply()
  return n.toNumber()
}

export const totalSupply = async () => {
  const n = await contract.totalSupply()
  return n.toNumber()
}

export const ownerRemainingTokens = async () => {
  const n = await contract.reserve()
  return n.toNumber()
}

// write functions
export const beastsMint = async (price, signer, amount) => {
  console.log('beasts Mint', signer, amount, price);
  const n = await contract.beastsSale(signer, amount, {value: utils.parseEther(price.toString())});
  await n.wait();
  return n
}

export const labGrownBeastsMint = async (price, signer, amount) => {
  console.log('lab Grown Beasts Mint', signer, amount, price);
  const n = await contract.labGrownBeastsSale(signer, amount, {value: utils.parseEther(price.toString())});
  await n.wait();
  return n
}

export const postMintSale = async (price, signer, amount) => {
  console.log('post Mint', signer, amount, price);
  const n = await contract.postMintSale(signer, amount, {value: utils.parseEther(price.toString())});
  await n.wait();
  return n
}