import React, {useState} from 'react'

import './counter.scss'

import { FaEthereum } from 'react-icons/fa';

export default function Counter({ maxCount, price, count, setCount }) {
    // const [counterValue, setCounterValue] = useState(1);

    const handleCounterIncrease = () => {
        if(count < maxCount) {
            // setCounterValue(counterValue + 1);
            setCount(count + 1);
        }
        else
            return
    }
    
    const handleCounterDecrease = () => {
        if(count > 1) {
            // setCounterValue(counterValue - 1)
            setCount(count - 1);
        }
        else
            return
    }

    return (
        <div className="counter-container">
            {/* <h1>You can mint upto {maxCount} tokens</h1> */}
            <div className="counter">
                <button onClick={() => handleCounterDecrease()}>-</button>
                <h1>{count}</h1>
                <button onClick={() => handleCounterIncrease()}>+</button>
            </div>

            <p className="eth-cost">
                {/* <FaEthereum />  */}
                <br />
                {(count * price).toFixed(3)} ETH
            </p>
        </div>
    )
}
